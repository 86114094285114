const TOKEN_KEY = 'token';
const USER_KEY = 'user';

export const getTokenInLocal = () => {
  const tokenString = localStorage.getItem(TOKEN_KEY);
  const userToken = JSON.parse(tokenString);
  return userToken;
};

export const setTokenInLocal = (userToken) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(userToken));
};

export const clearTokenInLocal = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getUserInLocal = () => {
  const userString = localStorage.getItem(USER_KEY);
  const user = JSON.parse(userString);
  return user;
};

export const setUserInLocal = (user) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  // Sau nay thay bang userToken.token
};

export const clearUserInLocal = () => {
  localStorage.removeItem(USER_KEY);
};

export const getUserFullName = () => {
  const userInfo = getUserInLocal();
  if (userInfo !== null) {
    return (userInfo.last_name + ' ' + userInfo.first_name).trim();
  }
  return null;
};

export const getUserRole = () => {
  const userInfo = getUserInLocal();
  if (userInfo !== null) {
    return userInfo.role;
  }
  return null;
};
