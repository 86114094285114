import randomColor from 'randomcolor';

/**
 * Đổi thời gian hệ thống sang thời gian trong JS
 * @param {} datetimeStr 
 * @returns 
 */
export const parseServerDatetime = (datetimeStr) => {
  if (hasEmpty(datetimeStr))
    return datetimeStr;
  try {
    const endIndex = datetimeStr.indexOf('m');
    const str = datetimeStr.substring(0, endIndex);
    return new Date(str);
  } catch (error) {
    return datetimeStr;
  }
};

export const currencyToString = (number) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
};

export const numberToString = (number) => {
  return new Intl.NumberFormat('de-DE').format(number);
};

export const updateDateStrFormat = (dateStr) => {
  return dateStr.replace(/-/g, '/');
}

export const stringToNumber = (str) => {
  let result = str.replace('.', '');
  result = result.replace(',', '.');

  return result;
};

export const numberToStringWithDots = (number) => {
  try {
    number = number.toString();
    if (number.length % 3 == 0) return number.match(/.{1,3}/g).join(' ');
    else return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
  } catch (error) {
    return number;
  }
};

/**
 * yyyy-mm-dd object to dd-mm-yyyy string
 * @param {*} dateStr 
 * @returns 
 */
export const displayDatetimeObject = (dateStr) => {
  if (hasEmpty(dateStr)) return dateStr;
  try {
    return new Date(dateStr).toLocaleDateString('vi-VN');
  } catch (error) {
    return dateStr;
  }
};

/**
 * yyyy-mm-dd to datetime object
 * @param {*} dateStr 
 * @returns 
 */
export const ymdToObject = (dateStr) => {
  if (hasEmpty(dateStr)) return dateStr;
  try {
    return new Date(dateStr);
  } catch (error) {
    return dateStr;
  }
};

export const dmyToObject = (dateStr) => {
  if (hasEmpty(dateStr)) return dateStr;
  try {
    let dateParts;
    if (dateStr.includes("/"))
      dateParts = dateStr.split("/");
    else
      dateParts = dateStr.split("-");
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  } catch (error) {
    return dateStr;
  }
};

export const dmyToYmd = (dateStr) => {
  if (hasEmpty(dateStr)) return dateStr;
  try {
    var d = ymdToObject(dateStr);
    if (typeof d === 'string')
      d = dmyToObject(dateStr);
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(d - tzoffset)).toISOString().slice(0, -1);
    return localISOTime.split('T')[0];
  } catch (error) {
    return dateStr;
  }
};

export const hasEmpty = (...args) => {
  args.forEach((arg) => {
    if (arg === undefined || arg === null) return true;
    else if (typeof arg === 'string' && (arg.match(/^\s*$/) === null || arg.trim() === '')) return true;
  });
  return false;
};

export const randomColors = (count, luminosity = '', isColumnBorder = false, color = 'red') => {
  if (isColumnBorder) {
    return ['#343434'];
  }

  const ARRAY_7_COLOR = ['rgba(75, 192, 192)', 'rgba(255, 99, 132)', 'rgba(255, 159, 64)', 'rgba(255, 205, 86)', 'rgba(54, 162, 235)', 'rgba(153, 102, 255)', 'rgba(201, 203, 207)'];
  let arrResultColor = ARRAY_7_COLOR.slice(0, count);

  if (count > ARRAY_7_COLOR.length) {
    let arrRandomColor = randomColor({ hue: color, luminosity: luminosity, count: count });
    arrResultColor = arrResultColor.concat(arrRandomColor);
  }

  return arrResultColor;
};

export const getDataFilter = (s, d) => {
  if ((s.length === 1 && s[0] === '') || s.length === 0) return d;
  else return d.filter((item) => s && s.length > 0 && s.includes(item.label));
};

export const getDataFilterFarm = (s, d) => {
  if ((s.length === 1 && s[0] === '') || s.length === 0) return d;
  else return d.filter((item) => s && s.length > 0 && s.includes(item.farm_id));
};

export const fixedNumber = (num, digits) => {
  try {
    return Number(num).toFixed(digits)
  } catch (error) {
    return num
  }
}