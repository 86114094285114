import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import { getTokenInLocal, getUserInLocal, getUserRole } from './functions/authentication';
import { parseServerDatetime } from './functions/utils';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

export const isAuthenticated = () => {
  const info = getTokenInLocal();
  const token = info?.token;
  const expiredDate = info?.expires_at;

  if (expiredDate === undefined) return false;

  const expiry = parseServerDatetime(expiredDate) / 1000; // in milli-seconds
  const now = new Date() / 1000;
  if (expiry < now) {
    localStorage.clear();
    return false;
  }

  return info !== null && token !== undefined;
};

export const isAdmin = () => {
  return isAuthenticated() && (getUserRole() === 'ADMIN' || getUserRole() === 'COOPERATIVE' || getUserRole() === 'BUSINESS');
};

export const isFarmer = () => {
  return isAuthenticated() && getUserRole() === 'FARMER';
};


const UnauthenticatedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (!isAuthenticated() ? <Component {...props} /> : <Redirect to="/dashboard" />)} />;

const AuthenticatedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />)} />;

// const AdminAuthenticatedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => isAuthenticated() ? (isAdmin() ? <Component {...props} /> : <Redirect to={'/dashboard'} />) : <Redirect to="/login" />} />;
const AdminAuthenticatedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => isAuthenticated() ? (isAdmin() ? <Component {...props} /> : (() => { alert("Không có quyền truy cập"); return <Redirect to={'/dashboard'} /> })()) : <Redirect to="/login" />} />;

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Home = React.lazy(() => import('./views/pages/home/Home'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const About = React.lazy(() => import('./views/pages/home/About/About'));
const AgriculturalProducts = React.lazy(() => import('./views/pages/home/AgriculturalProduct/AgriculturalProducts'));
const OCOP = React.lazy(() => import('./views/pages/home/AgriculturalProduct/OCOP'));
const Technique = React.lazy(() => import('./views/pages/home/Technique/Technique'));
const TechniqueDetailArtichoke = React.lazy(() => import('./views/pages/home/Technique/TechniqueDetailArtichoke'));
const TechniqueDetailRose = React.lazy(() => import('./views/pages/home/Technique/TechniqueDetailRose'));
const TechniqueDetaiAcipenser = React.lazy(() => import('./views/pages/home/Technique/TechniqueDetailAcipenser'));
const TechniqueDetailLaba = React.lazy(() => import('./views/pages/home/Technique/TechniqueDetailLaba'));
const TechniqueDetailRaspberry = React.lazy(() => import('./views/pages/home/Technique/TechniqueDetailRaspberry'));
const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ProductInformation = React.lazy(() => import('./views/pages/production-information/ProductInformation'));
const Finish = React.lazy(() => import('./views/pages/Finish'));
const LinearStepper = React.lazy(() => import('./views/pages/linearstepper/LinearStepper'));
const ProfileEditor = React.lazy(() => import('./views/pages/profile/ProfileEditor'));
const ProfileViewer = React.lazy(() => import('./views/pages/profile/ProfileViewer'));
const UserInsert = React.lazy(() => import('./views/information-management/Users/Insert'));
const UserUpdate = React.lazy(() => import('./views/information-management/Users/Update'));
const Details = React.lazy(() => import('./views/information-management/Users/Details'));
const Smallholding = React.lazy(() => import('./views/information-management/Users/Smallholding'));
const Users = React.lazy(() => import('./views/user-management/users/Users'));
const FarmInsert = React.lazy(() => import('./views/information-management/farm/FarmInsert'));
const FarmUpdate = React.lazy(() => import('./views/information-management/farm/FarmUpdate'));
const AgriculturalInsert = React.lazy(() => import('./views/information-management/agricultural-product/AgriculturalInsert'));
const AgriculturalUpdate = React.lazy(() => import('./views/information-management/agricultural-product/AgriculturalUpdate'));
const ListAgriculturalFarm = React.lazy(() => import('./views/information-management/agricultural-product/ListAgriculturalFarm'));
const TreatmentMethodDetail = React.lazy(() => import('./views/advisory/treatment-method/TreatmentMethodDetail'));
const TreatmentMeasure = React.lazy(() => import('./views/advisory/treatment-method/TreatmentMeasure'));
const TreatmentSymptom = React.lazy(() => import('./views/advisory/treatment-method/TreatmentSymptom'));
const DiseasesUpdate = React.lazy(() => import('./views/information-management/diseases/DiseasesUpdate'));
const TreatmentUpdate = React.lazy(() => import('./views/advisory/treatment-method/treatment/TreatmentUpdate'));
const SeedInsert = React.lazy(() => import('./views/information-management/seed/SeedInsert'));
const SeedUpdate = React.lazy(() => import('./views/information-management/seed/SeedUpdate'));
const SpecieUpdate = React.lazy(() => import('./views/information-management/species/SpeciesUpdate'));
const VarietyInsert = React.lazy(() => import('./views/information-management/variety/VarietyInsert'));
const VarietyUpdate = React.lazy(() => import('./views/information-management/variety/VarietyUpdate'));
const PlaceHolderUpdate = React.lazy(() => import('./views/information-management/placeholder/PlaceHolderUpdate'));
const PlaceHolderInsert = React.lazy(() => import('./views/information-management/placeholder/PlaceHolderInsert'));
const Harvesting = React.lazy(() => import('./views/information-management/harvesting/Harvesting'));
const HarvestingInsert = React.lazy(() => import('./views/information-management/harvesting/HarvestingInsert'));
const HarvestingUpdate = React.lazy(() => import('./views/information-management/harvesting/HarvestingUpdate'));

class App extends Component {
  render() {
    document.title = process.env.REACT_APP_NAME;

    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/" name="Trang chủ" render={(props) => <Home {...props} />} />
            <Route exact path="/nong-san" name="Nông sản" render={(props) => <AgriculturalProducts {...props} />} />
            <Route exact path="/ocop" name="Nông sản" render={(props) => <OCOP {...props} />} />
            <Route exact path="/techndetail" name="Kỹ thuật" render={(props) => <TechniqueDetailArtichoke {...props} />} />
            <Route exact path="/ky-thuat" name="Kỹ thuật" render={(props) => <TechniqueDetailArtichoke {...props} />} />
            <Route exact path="/ky-thuat/atiso" name="Kỹ thuật trồng atiso" render={(props) => <TechniqueDetailArtichoke {...props} />} />
            <Route exact path="/ky-thuat/hoa-hong" name="Kỹ thuật trồng hoa hồng" render={(props) => <TechniqueDetailRose {...props} />} />
            <Route exact path="/ky-thuat/ca-tam" name="Kỹ thuật nuôi cá tầm" render={(props) => <TechniqueDetaiAcipenser {...props} />} />
            <Route exact path="/ky-thuat/chuoi-laba" name="Kỹ thuật trồng chuối laba" render={(props) => <TechniqueDetailLaba {...props} />} />
            <Route exact path="/ky-thuat/phuc-bon-tu" name="Kỹ thuật trồng phúc bồn tử" render={(props) => <TechniqueDetailRaspberry {...props} />} />
            <Route exact path="/forgot" name="Đặt lại mật khẩu" render={(props) => <Forgot {...props} />} />
            <Route exact path="/register" name="Đăng ký" render={(props) => <Register {...props} />} />
            <Route exact path="/gioi-thieu-lac-duong" name="Giới thiệu" render={(props) => <About {...props} />} />

            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />

            <UnauthenticatedRoute exact path="/login" name="Đăng nhập" component={Login} />

            <AuthenticatedRoute path="/input-form-success" name="Hoàn thành" component={Finish} />
            <AuthenticatedRoute path="/profile-viewer" name="Thông tin cá nhân" component={ProfileViewer} />
            <AuthenticatedRoute path="/profile-editor" name="Chỉnh sửa thông tin cá nhân" component={ProfileEditor} />
            <AuthenticatedRoute exact path="/users/details/:id" name="Xem thông tin chi tiết" component={Details} />
            <AuthenticatedRoute exact path="/users/details/:id/farm/:id/agricultural/list" name="Xem - sửa nông sản" component={ListAgriculturalFarm} />
            <AuthenticatedRoute path="/linear-stepper" name="Stepper" component={LinearStepper} />
            <AuthenticatedRoute path="/product-information" name="Phiếu thu thập thông tin" component={ProductInformation} />
            <AuthenticatedRoute exact path="/advisory/treatment-method/detail/:id" name="Thông tin sâu bệnh" component={TreatmentMethodDetail} />
            <AuthenticatedRoute exact path="/advisory/treatment-method/symptom/:id" name="Thông tin triệu chứng" component={TreatmentSymptom} />
            <AuthenticatedRoute exact path="/advisory/treatment-method/measure/:id" name="Thông tin phòng ngừa" component={TreatmentMeasure} />

            <AdminAuthenticatedRoute exact path="/users/update/:id" name="Chỉnh sửa thông tin nông hộ" component={UserUpdate} />
            <AdminAuthenticatedRoute exact path="/farm/insert/:id" name="Thêm nông trại" component={FarmInsert} />
            <AdminAuthenticatedRoute exact path="/users/details/:id/farm/:id/agricultural/insert" name="Thêm nông sản" component={AgriculturalInsert} />
            <AdminAuthenticatedRoute exact path="/users/details/:id/farm/update/:id" name="Sửa nông trại" component={FarmUpdate} />
            <AdminAuthenticatedRoute exact path="/users/details/:id/farm/:id/agricultural/update/:id" name="Xem - sửa nông sản" component={AgriculturalUpdate} />
            <AdminAuthenticatedRoute exact path="/disease/update/:id" name="Chỉnh sửa thông tin sâu bệnh" component={DiseasesUpdate} />
            <AdminAuthenticatedRoute exact path="/plant-treatment/update/:id" name="Chỉnh sửa thông tin phương pháp trị bệnh" component={TreatmentUpdate} />
            <AdminAuthenticatedRoute exact path="/seed/insert" name="Thêm thông tin giống cây trồng" component={SeedInsert} />
            <AdminAuthenticatedRoute exact path="/seed/update/:id" name="Chỉnh sửa thông tin giống cây trồng" component={SeedUpdate} />
            <AdminAuthenticatedRoute exact path="/species/insert" name="Chỉnh sửa thông tin giống cây trồng" component={SeedInsert} />
            <AdminAuthenticatedRoute exact path="/species/update/:id" name="Chỉnh sửa thông tin giống cây trồng" component={SpecieUpdate} />
            <AdminAuthenticatedRoute exact path="/variety/insert" name="Chỉnh sửa thông tin giống cây trồng" component={VarietyInsert} />
            <AdminAuthenticatedRoute exact path="/variety/update/:id" name="Chỉnh sửa thông tin giống cây trồng" component={VarietyUpdate} />
            <AdminAuthenticatedRoute exact path="/farm/:id/placeholder/insert" name="Thêm thông tin thửa cây trồng" component={PlaceHolderInsert} />
            <AdminAuthenticatedRoute exact path="/user/:id/farm/:id/placeholder/:id/harvesting/insert" name="Thêm thu hoạch" component={HarvestingInsert} />
            <AdminAuthenticatedRoute exact path="/user/:id/farm/:id/placeholder/:id/harvesting/:id/update" name="Sửa thu hoạch" component={HarvestingUpdate} />
            <AdminAuthenticatedRoute exact path="/user/:id/farm/:id/placeholder/:id/harvesting/list" name="Thông tin thu hoạch" component={Harvesting} />
            <AdminAuthenticatedRoute exact path="/users/insert" name="Thêm mới" component={UserInsert} />
            <AdminAuthenticatedRoute exact path="/farm/:id/placeholder/update/:id/" name="Chỉnh sửa thông tin thửa cây trồng" component={PlaceHolderUpdate} />
            <AuthenticatedRoute path="/" name="Trang quản trị" component={DefaultLayout} />

            <Redirect to="/404" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
