import { combineReducers } from "redux";
import { stepReducer } from "./stepReducer";
import { changeState } from "./changeReducer";
import { typeReducer } from "./typeReducer";
import { userReducer } from "./userReducer";

const rootReducer = combineReducers({
  step: stepReducer,
  change: changeState,
  type: typeReducer,
  user: userReducer
})

export default rootReducer